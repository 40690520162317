.Modal {
    position: fixed;    
    bottom: 20%;
    
    margin: 0rem;    
    z-index: 100;    
    
    transition: all 0.3s ease-out;        
    background-color: transparent;
    
    
}
.Content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Message {
    font-size: inherit;
    color: white;
}