.entryPassesSummary-element-container{
    font-size: small;
    font-weight: bold;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;	
	border-radius: 15px;	
	background-color: rgb(71, 71, 71);
	color: white;	
	margin-bottom: 5px;	
	border-bottom: 0.5px solid rgb(226, 226, 226) ;
    padding-left: 5px;
    padding-right: 5px;
}