.achievementscore-containter{
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px 24px;
    box-shadow: 0px 0px 8px rgba(62, 46, 126, 0.2);
    color: #3E2E7E;
}

.achievementscore-containter::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('./AchievementScoreResources/bg1-odd.svg'),  url('./AchievementScoreResources/bg2-odd.svg');
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: contain, contain;
    pointer-events: none;
    content: '';
}

.achievementscore-containter:nth-child(even) {
    background-color: #3E2E7E;
    color: #fff;
}

.achievementscore-containter:nth-child(even)::after {
    background-image: url('./AchievementScoreResources/bg1-even.svg'),  url('./AchievementScoreResources/bg2-even.svg');
}

.achievementscore-containter + .achievementscore-containter {
    margin-top: 12px;
}

.achievementscore-score-cat1{
    border: 1px solid #b4da4d !important;    
    background-color: #b4da4d !important;
}
.achievementscore-score-cat2{
    border-color: #4da6da !important;
    background-color: #4da6da !important;
}
.achievementscore-score-cat3{
    border-color: #da2a33 !important;
    background-color: #da2a33 !important;
}
.achievementscore-name{
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    margin-bottom: 4px
}
.achievementscore-score{    
    border: 1px solid;     
    align-self: center;
    color: white;
    width: 40%;
}
.achievementscore-details{
    font-size: large;
    font-weight: bolder;
    background-color: #bbbbbb;
    color: white;
    align-self: center;
    padding-left: 5px;
    padding-right: 5px;
 
}
.achievementscore-stars{
    text-align: left;    
}
.achievementscore-stars svg{
    align-self: center;
    vertical-align: top;
    margin-right: 4px;
    fill: currentColor;
}
