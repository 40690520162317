.buyEventButton-container{
    width: 100%;
}
.buyEventButton-container-btn{
    width: 100%;
    min-height: 70px;
}
.buyEventButton-btn-content-container{
    width: 100%;
    display: grid;
	grid-template-columns: 2% 80% 18%;
	grid-template-rows: auto;
	column-gap: 0;
	row-gap: 0;
	grid-template-areas: 
	"left center right";
    justify-items: center;
	align-items: center;
}
.buyEventButton-btn-content-left{
    margin-right: 10px;
    grid-area: left;
}
.buyEventButton-btn-content-center{
    margin-right: 10px;
    font-size: 10px;
    grid-area: center;
    font-size: medium;
}
.buyEventButton-btn-content-right{
   cursor: pointer;
   margin-right: 10px;
   align-items: flex-end;
   grid-area: right;
}
.buyEventButton-btn-content-fade{
    opacity: 0.8;
    background-color: aqua;
}
.buyEventButton-btn-content-container-params{    
    margin-left: 20px;
    display: flex;
    flex-direction: row;
}
.buyEventButton-important{
    margin-top: 10px;
    font-size: medium;
    font-weight: bold;
}