.login-password-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 20px;    
    padding-top: 20px;
    align-content: center;
}
.submit-button {
    margin-bottom: 10px;
}
.auth-password-phone-btn {
    margin-top: 10px;
}
.login-password-pass:-webkit-autofill{
    align-self: center;
}
.login-password-pass-btn button{
    margin-top: 0;
}
.login-password-info{
    font-size: x-small;
}