.usePayUCardForm-container * {    
    font-size: 14px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.usePayUCardForm-container {
    text-align: center;
    width: 100%;
    margin: 10px auto 10px;
    display: block;
    border-radius: 5px;
    box-sizing: border-box;
}

.usePayUCardForm-card-container {
    margin-bottom: 5px;
    border-radius: 6px;
    padding: 10px;
    background: rgb(2,0,60);
    text-align: left;
}

.payu-card-form {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
}

.response-success {
    color: #438F29;
}

.response-error {
    color: #990000;
    font-weight: bold;
}
.usePayUCardForm-container button{
    height: 50px;
}