.newscheduledetils-container{
    display: flex;
    flex-direction: column;
    min-height: 900px;
}
.newscheduledetils-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
    
}

.newscheduledetils-table{
    margin-top: 30px;
    margin-bottom: 30px;
    
    text-align: left;
}

.newscheduledetils-table th{
    text-align: center;
    font-size: large;
}

.newSchedule-details-bundle{
    margin-bottom: 2rem;    
}