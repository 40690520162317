.mySignup-container{
  min-height: 800px;
}
.my-signup-page {
  padding: 24px;
  text-align: left;
}

.header {
  display: flex;
  align-items: center;
  background-color: #3E2E7E;
  padding: 18px;
}

.header-arrow {
  cursor: pointer;
  margin-right: 24px;
}

.header-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

.header-icon {
  margin-left: auto;
}

.my-signup-page-actions {
  padding-top: 28px;
}