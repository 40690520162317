.signups-list-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;    
}

.signups-list-empty{
    font-size: x-large;
    color: gray;
    align-self: center;
    justify-self: center;
    border: 1px solid;
    border-radius: 20px;
    box-shadow: initial ;
    padding: 15px;    
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    -webkit-box-shadow: 5px 5px 15px 0px #3E2E7E;
    -moz-box-shadow: 5px 5px 15px 0px #3E2E7E;
    box-shadow: 5px 5px 10px 0px #3E2E7E;
}