.card-price-tag-container {
	display: grid;
	grid-template-rows: 50px 60px 40px 15px 10px 10px 25px ;	
	grid-template-columns: 15% 70% 15%;
	column-gap: 0;
	row-gap: 0;
	justify-items: center;
	align-items: top;	
	
}
.card-price-tag-header {	
	grid-row: 1;
	grid-column: 2;
	z-index: 10;
}

.card-price-tag-background {
	grid-row: 2;
	grid-column: 2;	
	z-index: 2;
}


.card-price-tag-text-one {    
	grid-row: 3;
	grid-column: 2;
	z-index: 10;	
	color: #383336;    
}
.card-price-tag-text-two {    
	grid-row: 4;
	grid-column: 2;
	z-index: 10;	
	
}
.card-price-tag-text-three {    
	grid-row: 5;
	grid-column: 2;
	z-index: 10;		
}
.card-price-tag-text {
	margin-left: 5%;
	margin-right: 5%;
	color: black;
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 19px;
	color: #383336;
}


.card-price-tag-line {
	grid-row: 6;
	grid-column: 2;
    z-index: 10;
}

.card-price-tag-barcode {
	grid-row: 8;
	grid-column: 2;
    z-index: 10;
}
.card-price-tag-footer {
	grid-row: 9;
	grid-column: 2;
    z-index: 10;
}

.card-price-tag-mid {
	grid-row: 7;
	grid-column: 2;
    z-index: 10;
}
