.toggle-btn-spinner{
    color: #3E2E7E;
}

.toggle-btn-switch{
    width: 60;
    height: 24;   
}

.toggle-btn-switch label{
    vertical-align: middle;
    
}
.toggle-btn-label{
    padding-right: 2rem;
}