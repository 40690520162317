.dashboard-container{
    display: flex;
    flex-direction: column;    
    /* margin-top: 50px;
	grid-template-rows: auto;
	grid-template-columns: 10% 80% 10%;
	column-gap: 0;
	row-gap: 30px; */
    justify-items: center;
    align-items: center;
    margin: 1rem;
    
}
.dashboard-item{
    margin-bottom: 20px;
}
.dashboard-item-one{
    grid-row: 1;
    grid-column: 2;
}
.dashboard-item-two{
    grid-row: 2;
    grid-column: 2;    
}
.dashboard-item-three{
    grid-row: 3;
    grid-column: 2;    
}
