.editable-field-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  height: 2.5rem;
  padding-top: 5px;
  padding-bottom: 5px;  
}
.editable-field-title{
  margin-right: 10px;
}
.editable-field-value-col {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}

.editable-input-field-value-col {
  display: flex;
  margin-right: 10px;
}

.editable-input-field-value-col > .form-control {
  margin-right: 5px;
  flex-grow: 1;
}

.editable-input-field-value-col > button {
  margin-left: 5px;
}

.editable-input-field-pin{
  width: 30px;
}