.depositsHistory-container{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.depositsHistory-list{
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: center;
    margin-left: 5px;
    margin-right: 10px;
}
.depositsHistory-list-item{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.depositsHistory-list-item-border{
    /* border-right: solid 1px ; */
    padding: 10px;
}
.depositsHistory-list-item-actions{
    text-align: center;
    padding: 5px;
}
.depositsHistory-sub{
    margin-top: 20px;
}
.depositsHistory-title{
    align-items: center;
    width: 100%;
}
.depositsHistory-list-item-red
{
    color: red;
}
.depositsHistory-list-item-green
{
    color: green;
}