.confirmModal-container {
  width: 343px;
  height: 234px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.confirmModal-title {
  width: 100%;
  padding: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  justify-content: flex-start;
}
.confirmModal-message {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 144%;
  color: #ffffff;
  padding-left: 20px;
}

.confirmModal-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  
}
.confirmModal-cancel-btn {
  color: #ffffff;
  background-color: transparent;
  margin-right: 50px;
}

.confirmModal-confirm-btn {
  width: 113px;
  height: 40px;

  background: #ffffff;
  border-radius: 16px;

  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #3e2e7e;
}
