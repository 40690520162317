.cardinfo-container {
	height: 197px;
	width: 290px;

	display: grid;
	grid-template-rows: 40% 80%;
	grid-template-columns: 80% 20%;
	background: #ffffff;

	background-image: url('./CardInfoResources/cardInfoBcg1.svg');
	background-repeat: no-repeat;
	margin-bottom: 20px;
	border-radius: 16px;
    box-shadow: 5px 5px 5px 1px #483f69;
    padding: 5px;
}
/* .cardinfo-container-icon-close {
} */
.cardinfo-container-title {
	padding-top: 10px;
	grid-row: 1;
	grid-column: 1;
	font-weight: bold;
	font-size: 20px;
	line-height: 27px;

	color: #3e2e7e;
}
.cardinfo-container-text {
	grid-row: 2;
	grid-column: 1 / span 2;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 144%;	
	color: #3e2e7e;
}
.cardinfo-container-icon-main {
	grid-row: 1 / span 2;
	grid-column: 2;
}
.cardinfo-container-buttons {
	grid-row: 3;
	grid-column: 1 / span 2;
}
