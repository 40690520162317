.socialsearch-result-container{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;    
    border-top: 0.5px solid gray;    
    border-bottom: 0.5px solid gray;    
    padding: 0.5rem;      
}
.socialsearch-result-text{    
    grid-row: 1;
    grid-column: 1;
    align-self: flex-start;
    margin-right: 0.5rem;
    margin-left: 1rem;    
    font-weight: bold;
}
.socialsearch-result-actions{    
    grid-row: 1;
    grid-column: 2;
    font-size: small;
    align-self: center;
}