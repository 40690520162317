.listElementContainer-container{
    border-top: solid 2px rgb(222, 222, 222);
    border-bottom: solid 2px rgb(222, 222, 222);
}
.listElementContainer-edge{
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
}
.listElementContainer-edge-danger{
    border-left: solid 5px red;
}
.listElementContainer-edge-success{
    border-left: solid 5px green;
}