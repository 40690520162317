.radiobtn-containner{
    margin: 5px;
}

/* .radiobtn-item {
	display: flex;
	margin-bottom: 36px;
	overflow: hidden;
} */

.radiobtn-item input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.radiobtn-item label {
	/* background-color: #e4e4e4; */
	/* color: rgba(0, 0, 0, 0.6); */
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 8px;
	margin-right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	/* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1); */
	transition: all 0.1s ease-in-out;
}

.radiobtn-item label:hover {
	cursor: pointer;
}

.radiobtn-item input:checked + label {
	background-color: #1d65ff;
	box-shadow: none;
}

.radiobtn-item label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.radiobtn-item label:last-of-type {
	border-radius: 0 4px 4px 0;
}
