.signup-event-details-container {
	display: grid;
	/* grid-template-columns: 30% 30% 30%; */
	grid-template-rows: auto;
	column-gap: 20px;
	row-gap: 0;
	grid-template-areas: 'amount payment date';
	justify-items: stretch;
	align-items: center;
	padding: 2px;
	margin-bottom: 10px;
	width: 100%;
	margin-left: 10px;
	margin-right: 10px;

	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
}

.signup-event-details-date {
    grid-area: date;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;    
}

.signup-event-details-amount {
    grid-area: amount;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.signup-event-details-payment {
    grid-area: payment;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.signup-event-details-date-icon{
	padding-right: 5px;
}
.signup-event-details-amount-icon{
	padding-right: 10px;
}
.signup-event-details-payment-icon{
	padding-right: 5px;
}
