.account-card{
    text-align: center;
}

.account-card-icon{
    height: 30px; 
    filter: invert(100%);   
}

.account-card-text{
    color: white;    
    text-align: start;
}

