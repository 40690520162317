.social-userprofile-container{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    color: black;

}
.social-userprofile-title{
    margin-top: 1rem;
    font-weight: bold;
    align-self: flex-start;
}
.social-userprofile-options{
    display: inline-block;
}
.social-userprofile-option{
    display: flex;
    flex-direction: row;    
    border-bottom: 0.5px solid rgb(226, 226, 226) ;
    
}
.social-userprofile-option-label label{
    padding-right: 1rem;
    margin-bottom: 0;
}
.social-userprofile-option-button{
    align-self: center;
    padding-right: 1rem;
    padding-top: 5px;    
    
}