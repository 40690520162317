.catlist-category-bar-container {
	display: flex;
	flex-direction: row;
	background: #ffffff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	padding: 10px;
    margin: 10px 20px 0px 20px;    
}

.catlist-category-bar-text {
	margin-left: 10%;
	flex: 2;
	color: #383336;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
}

.catlist-category-bar-icon {
	flex: 1;
}

.rotate-180{
    transform: rotate(180deg);
}
