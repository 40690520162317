.login-container {
    margin: 30px;
    padding-top: 30px;
}
.auth-phone-btn {
    margin-top: 30px;
}
.phone-no {
    margin-bottom: 20px;
}
.auth-emaillink-info::before {
	display: inline;
	content: '✉ ';
}
.auth-emaillink-info{
	color: blue;
}