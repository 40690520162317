.my-next-event-card{
    text-align: center;
}

.my-next-event-card-icon{
    height: 30px;    
    filter: invert(100%);
}

.my-next-event-card-text{
    color: white;    
}

