.new-event-container {
	display: grid;	
	grid-template-columns: 4px 35% 45% 5% 1%;
	grid-template-rows: auto;
	column-gap: 0;
	row-gap: 0;
	grid-template-areas: 
	"edge status desc tool icon"
	"edge details details details icon";
	justify-items: center;
	align-items: center;
	/* border-radius: 5px; */
	/* margin-bottom: 5px; */
	/* box-shadow: 1px 1px 1px; */
	padding: 2px;
	margin-bottom: 10px;
	/* background: #ffffff; */
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	width: 100%;

}
.newscheduledetils-top-picture img{
	width: 100%;
}
.new-event-left{	
	grid-area: status;
	display: inline-block;
	vertical-align: top;
}

.new-event-center{	
	grid-area: desc;
	display: inline-block;
	vertical-align: top;
}

.new-event-right {
	grid-area: tool;
} 

.new-event-details-icon{			
	grid-area: icon;
	margin-left: 10px;
	vertical-align: middle;
	
}

.new-event-details-icon svg{	
	height: 100%;
}

.new-event-bottom {	
	grid-area: details;
	width: 100%;		
}

.new-event-time-text {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 19px;
	color: #f86264;
}

.new-event-center-title {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */
}
.new-event-center-level {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */
}
.new-event-desc {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
}
.new-event-desc-mark {
	font-size: 15px;

}
.new-event-icon-text{
	display: flex;
	flex-direction: row;	
}