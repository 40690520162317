.regularPayments-container{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.regularPayments-list{
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: center;
    margin-left: 5px;
    margin-right: 10px;
}
.regularPayments-list-item{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.regularPayments-list-item-border{
    /* border-right: solid 1px ; */
    padding: 10px;
}
.regularPayments-list-item-actions{
    text-align: center;
    padding: 5px;
}
.regularPayments-sub{
    margin-top: 20px;
}
.regularPayments-title{
    align-items: center;
    width: 100%;
}
.regularPayments-cvv-modal{
    display: flex;
    flex-direction: row;
    width: 100px;
    height: 100%;
    align-content: center;
    vertical-align: middle;
}