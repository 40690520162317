.feedback-container{
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 20px;
}
.feedback-text{
    margin: 10px;
    font-size: medium;
}

.feedback-text-input{
    border-radius: 5px;
    width: 100%;
    
}
.feedback-footer{
    width: 100%;
    bottom: 1rem;
    font-size: small;
    font-weight: lighter;
}