.social-contacts-container{
    display: flex;
    flex-direction: column;        
}
.social-contacts-search{    
    position: fixed;
    width: 300px;
    top: 3.5rem;
    z-index: 200;    
    align-self: center;    
}
.social-contacts-data{    
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-top: 6rem;
}
.social-contacts-list{
    width: 100%;
    align-items: center;
}
.social-contacts-section{
    align-self: center;    
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;    
}

.social-contacts-section-sub{
    align-self: center;    
    font-size: x-small;
}