.tabs-container {
    width: 90%;
    margin-top: 30px;
	margin-left: 5%;
	margin-right: 5%;
}

.tabs-header {	
    background: #f5f4f8;	
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: start;
    width: 100%;
    height: 35px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;    
}
.tabs-tab {
    height: 100%;	
    width: 50%;
    padding-top: 5px;
 
    border-radius: 20px;    
    color: #383336;
/* 
	font-style: normal;
	font-weight: normal;
	line-height: 16px;
	font-size: 12px;
	 */
}

.tabs-tab-active {
    height: 100%;
    width: 50%;
    padding-top: 5px;
    background: #3e2e7e;	
    border-radius: 20px;
    color: #ffffff;
    /* font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;	
	 */
}
