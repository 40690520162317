.filters-container{
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.filters-bar{    
    padding: 10px;
    box-shadow: 10px;
}

.filters-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.filters-content-filter{
    margin-bottom: 15px;
}