.newsmesagges-container {
	display: flex;
	flex-direction: column;
    
}
.newsmesagges-containter img {
	border-radius: 15px;
	width: 290px;
	box-shadow: 5px 5px 5px 1px #483f69;
    margin-bottom: 10px;
}
