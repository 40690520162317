.catlist-list-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.catlist-list-bar{
    font-size: x-large;
    color: white;
    border: 1px solid black;
    box-shadow: 5px 5px 3px black;
    align-self: center;
}