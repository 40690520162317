.event-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
 
}
.event-actions-purchaseOptions{
    width: 100%;
    
}
.event-actions-purchaseOptions-btn{
    margin-bottom: 10px;
}
.event-actions-signOut {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid rgb(62 46 126 / 20%);
    margin-top: 40px;
    align-items: center;
}
.event-actions-signOut-btn{
    align-items: center;
}
.event-actions-signOut-btn .cancelButton-container{
    margin-top: 20px;
}
.event-disclaimer{
    display: flex;
    flex-direction: column;
    font-size: small;
    align-items: center;
}
.event-disclaimer-instruction{
    padding-top: 1rem;
    font-weight: bold;
}
.event-disclaimer-text{
    text-align: center;
}
.event-actions-discount{
    display: flex;
    flex-direction: row;
    margin: 5px;
    margin-bottom: 30px;
}
.event-actions-discount button{
    height: 40px;
}
.event-actions-params{
    display: flex;
    flex-direction: column;
    align-items: center;    
    
    margin-bottom: 20px; 
}
.event-actions-params-monthly-txt{
    text-align: center;
    font-size: large;
    font-weight: bold;
}
.event-actions-purchaseOptions-title{
    font-size: larger;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
}