.data-button-container {	
    border: 1px solid rgb(255,255,255,.4);
	box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    margin: 3px;
    font-size: 12px;
}
.data-button-text {
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    
}
.data-button-data {    
    color: white;
    border-left: 1px solid rgb(255,255,255,.4);
    padding-left: 6px;
    padding-right: 6px;    
}

.data-button-function{    
    background-color: rgb(255,255,255);    
    border-radius: 0 8px 8px 0px;
    width: 20px;
}
.data-button-function-icon svg{     
    width: 5px;
    height: 5px; 
}

