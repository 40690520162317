.purchaseOptionsDetails-container{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.purchaseOptionsDetails-charge{
    width: 100%;
    border-bottom: solid 1px;
}
.purchaseOptionsDetails-title{
    margin: 5px;
}
.purchaseOptionsDetails-details{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: width 600ms ease-out, height 600ms ease-out;
    margin: 5px;
}
.purchaseOptionsDetails-payments{
    margin-left: 30px;
    margin-top: 5px;
    margin-right: 10px;    
    align-items: flex-end;
}
.purchaseOptionsDetails-payments-details{
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    align-content: flex-end;
    margin-bottom: 10px;
    
}
.purchaseOptionsDetails-payments-details-status{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.purchaseOptionsDetails-payments-details-btn{
    width: 100%;
    align-items: flex-end;
}
.purchaseOptionsDetails-payments-details-btn button{
    height: 40px;
    width: 150px;
}

.purchaseOptionsDetails-payments-add{
    width: 100%;
    margin-top: 20px;
}
.purchaseOptionsDetails-payments-add button{
    height: 40px;
    width: 100%;
}