.social-score + .social-score {
  margin-top: 8px;
}

.social-score-row {
  display: flex;
  padding: 11px 13px;
  border-radius: 12px;
  color: #fff;
}

.social-score:nth-of-type(1) .social-score-row {
  background-color: #4BB3FD;
}
.social-score:nth-of-type(2) .social-score-row {
  background-color: #86CB92;
}
.social-score:nth-of-type(3) .social-score-row {
  background-color: #ED6A5A;
}

.social-score-arrow {
  margin-right: 8px;
  transform: rotate(-90deg);
}

.social-score-arrow.expanded {
  transform: rotate(0);
}

.social-score-label {
  font-weight: 700;
  flex-grow: 1;
  text-align: left;
}

.social-score-scores-points {
  font-weight: 400;
}

.social-score-scores-marks {
  position: relative;
  padding-left: 15px;
  font-weight: 300;
}

.social-score-scores-marks::before {
  position: absolute;
  left: 6px;
  margin-top: -1.5px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #fff;
  top: 50%;
  content: '';
}

.social-score-content {
  padding: 16px 20px 6px 20px;
}

.social-score-content-row {
  display: flex;
  justify-content: space-between;
  color: #3E2E7E;
  filter: blur(5px);
}