.myachievements-container
{
    padding: 1rem;          
}
.myachievements-title{
    color: #3E2E7E;
    font-size: 14px;
    margin-bottom: 12px;
    align-self: flex-start;
    text-align: left;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.myachievements-subtitle {
    opacity: .5;
    font-weight: 600;
    font-size: 16px;
    padding-left: 10px;
}

.myachievements-dances {
    margin-bottom: 32px;
}