.event-details {
    padding-bottom: 28px;
    border-bottom: 1px solid rgb(62 46 126 / 20%);
}

.event-details-title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    color: #3E2E7E;
}

.event-details-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.event-details-label {
    color: #383336;
    font-size: 14px;
    font-weight: 400;
}

.event-details-value {
    color: #3E2E7E;
    font-size: 14px;
    font-weight: 700;
}
.event-details-row + .event-details-row {
    margin-top: 18px;
}

.event-details-select {
    flex-shrink: 0;
    min-width: 140px;
}

