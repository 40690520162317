.socialsearch-container{    
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    margin-top: 3rem;
}
.socialsearch-bar{
    grid-row: 1;    
    align-self: center;    
    position: fixed;
    width: 300px;
    top: 3.5rem;
}
.socialsearch-filters-label{
    padding: 0.5rem ;    
    align-self: flex-end;
    margin-right: 15%;
    font-size: small;
}
.socialsearch-results{
    margin-top: 1rem;
    grid-row: 3;
}
.socialsearch-filters-button{
    padding: 1rem ;    
}