.card {
  background-color: inherit;
  margin: 1rem;
  border: none;  
}
.card-content:active{
  transform: scale(0.9);
}

.card-title {
  padding: 0;
  text-align: start;
  color: gray;
}
.card-content {
  
  display: flex;
  flex-wrap: wrap;  
  flex-direction: row;
  
  margin-bottom: 5px;
  padding: 1rem;    
  justify-content: space-between;
  color: white;  
  border-radius: 5px;
  
}

