.social-contact-container{
    display: grid;
    padding: 5px;
    grid-template-rows: 30px 10px auto auto;
    grid-template-columns: 40% auto ;
    border-radius: 8px;
    box-shadow: 1px 1px 2px 1px solid;
    margin-bottom: 1rem;    
    background-image: url("./SocialContactResources/socialContactBkg.svg");
    max-width: 350px;    
}
.social-contact-picture{
    grid-row: 2 / 4;
    grid-column: 1 ;
    align-self: center;
    justify-self: center;    
}
.social-contact-name{
    grid-row: 1 ;
    grid-column: 1 / span 2;
    font-weight: bold;
    font-size: 22px;    
    color: white;
    align-self: center;    
}
.social-contact-name-sub
{
    grid-row: 2;
    grid-column: 2 ;    
    font-size: 12px;    
    color: white;
    justify-self: right;
    font-weight: normal;
    align-self: flex-end;
}
.social-contact-fb{
    grid-row: 4 ;
    grid-column: 1;
    font-weight: bold;
    font-size: 22px;    
    color: white;
    align-self: flex-end;
    justify-self: start;    
}
.social-contact-info{
    grid-row: 3 ;
    grid-column: 2 ;
    align-self: center;
    align-content: flex-end;
    margin: 2px;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.social-contact-actions{    
    grid-row: 4 ;
    grid-column: 1 / span 2;
    align-self: center;   
    display: flex;
    flex-direction: row;    
    margin-top: 4px;
    margin-bottom: 4px;    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    
}
.social-contact-noresults-label{
    grid-row: 1 ;
    grid-column: 1 / span 2;
}
.social-contact-actions button.confirm {
    height: 40px;
	width: 100px;
}
.social-contact-actions button.cancel {
    height: 40px;
	width: 100px;
}