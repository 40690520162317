.data-label-container {		
	background: #f86264;
    border-radius: 4px;    
    padding-left: 4px;
	padding-right: 4px;
	margin-bottom: 4px;
	margin-right: 4px;
}
.data-label-text {	
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 25px;
	color: #ffffff;
}
