.radiobtns-containner{	
	background-color: hsl(0,0%,100%);

}
.radiobtns-title{
	padding: 5px;
	text-align: left;
	background-color: #88a1bb46

}
.radiobtns-buttons{
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 10px;
	padding: 0;
}

.radiobtns-border{
	border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}