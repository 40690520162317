.filters-container{
    margin: 20px;
}
.filters-content{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    padding-bottom: 5px;
}
.filters-content-filter{
    margin-right: 10px;
    width: 100%;
}
.filters-content-inline{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}