.signup-container {
	margin: 30px;
}
.submit-button {	
	
	color: white;
	text-transform: uppercase;
	border: none;
	margin-top: 10px;
	padding: 10px;
	font-size: 16px;
	font-weight: 100;
	letter-spacing: 10px;
}
.registered-container {
	padding-top: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.signup-text {
	padding-left: 5px;
	color: blue;
}

h1 {
	font-weight: 100;
	color: white;
	text-align: center;
	padding-bottom: 10px;
	border-bottom: 1px solid rgb(79, 98, 148);
}

p::before {
	display: inline;
	content: '⚠ ';
}
p{
	color: red;
}

input {
	display: block;
	box-sizing: border-box;
	width: 100%;
	border-radius: 16px;
	border: 1px solid white;
	font-size: 14px;
}

label {
	line-height: 2;
	text-align: left;
	display: block;
	font-size: 14px;
	font-weight: 200;
}


input:disabled {
	opacity: 0.4;
}


hr {
	margin-top: 30px;
}

.text-help{
	font-size: xx-small;
}

.pin-field {  
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: space-between;  
  }

.pin-field > .form-control-sm:not(:last-of-type) {
	margin-right: 10px;
}
  
.signup-checkbox-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 50px;
}
.signup-checkbox-input{
	width: 50px;
}
