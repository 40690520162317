.account-details-container {
	display: flex;
	flex-direction: column;
    color: black;
    margin-top: 20px;
	height: 100%;
}

.account-details-text-title {
	color: black;
	font-size: medium;
	text-align: start;
	font-weight: bold;
	margin: 0rem 0rem 0rem 1rem;	
}

.account-details-text-data {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;	
	margin-right: 30px;
	text-align: right;
	color: #3e2e7e;	

}

.account-details-feedback{
	margin-top: 20px;
}

.app-text-title-sub{	
	margin-right: 20px;
}
.account-details-avatar{
	margin-left: 1rem;
}
.account-details-userdata{
	display: flex;
	flex-flow: column;	
	justify-content: space-between;	
	align-content: center;
	
}
.account-details-userdata-row{
	display: flex;
	flex-flow: row;	
	justify-content: space-between;	
	margin-right: 20px;
	padding-top: 20px;
    padding-bottom: 20px;
	min-height: 2rem;
	border-bottom: 0.5px solid rgb(226, 226, 226) ;
}
.account-details-logout{
	width: 100%;
	margin-bottom: 20px;
}
.account-details-logout .btn{
	justify-content: center;
}
.account-details-userdata-row-field{	
	padding-top: 10px;
    padding-bottom: 10px;
	min-height: 2rem;
	border-bottom: 0.5px solid rgb(226, 226, 226) ;
}

.account-details-userdata-row-sub{
	display: flex;
	flex-direction: column;		
	flex-wrap: nowrap;
	margin-right: 20px
}

.account-details-userdata-row-sub-name{	
	margin-left: 10px
}
.account-details-userdata-row-sub-data{
	margin-left: 10px;
}
.account-details-userdata-row-sub-btn{
	margin-left: 20px;
}
.account-details-userdata-row-badge{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;	
	border-radius: 15px;	
	background-color: rgb(71, 71, 71);
	color: white;	
	margin-bottom: 10px;	
	border-bottom: 0.5px solid rgb(226, 226, 226) ;
}
.account-details-userdata .row button{
	margin-top: 5px;
}
.account-details-userdata-row-sub-col{
	display: flex;
	flex-direction: column;
	padding-top: 5;
	padding-bottom: 5;
	align-self: center;
}