.auth-container button{
	font-weight: bolder;
}
.submit-button {
	border-radius: 16px;
}
.cancel-button {
	border-radius: 16px;
}
.btn-primary:hover {
	border-radius: 16px;
}
.auth-buttons {
	border-radius: 16px;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
}
.auth-buttons-main
{
	margin-bottom: 10px;
}
.auth-buttons-separator{
	margin-top: 3	0px;
	margin-bottom: 10px;
}
.auth-buttons-separator-lines{
	border-bottom: 2px solid;
	border-top: 2px solid;
}
.auth-buttons-other{
	display: flex;
	flex-direction: column;
}
.auth-buttons-margin-top{	
	margin-top: 15px;
}
.auth-buttons-other-btn button{
	width: 250px;
	margin-top: 10px;	
}
.auth-buttons-other-btn img{
	width: 45px;		
	margin: 0;
}
.auth-buttons-other-label{
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: center;
}
.button-children-aligned{
	width: 100%;
	display: flex;
	flex-direction: row;	
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	margin: -10px;
	padding: 0px;

}
.phoneNumber_section {
	border-radius: 16px;
	margin: 30px;
}
.auth-text-header {
	font-size: x-large;
	margin-top: 20px;	
    font-weight: 200;
}

.auth-signup-btn {
	margin: 20px;
}
