.signup-event-containter {
	display: grid;	
	grid-template-columns: 4px 32% 64%;
	grid-template-rows: auto;
	column-gap: 0;
	row-gap: 0;
	grid-template-areas: 
	"edge status desc"
	"edge details details";
	justify-items: center;
	align-items: center;
	/* border-radius: 5px; */
	/* margin-bottom: 5px; */
	/* box-shadow: 1px 1px 1px; */
	padding: 2px;
	margin-bottom: 10px;	
	/* background: #ffffff; */
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	width: 100%;

}

.clickable {
	cursor: pointer;
}

.signup-event-bg-dark .signup-event-edge-left{
	width: 4px;
	height: 100%;
	justify-self: start;
	grid-area: edge;
	background-color: #3e2e7e;
}
.signup-event-bg-light .signup-event-edge-left{
	width: 4px;
	height: 100%;
	justify-self: start;
	background-color: #f86264;
	grid-area: edge;
}

.signup-event-left{	
	grid-area: status;
	display: inline-block;
	vertical-align: top;
	justify-self: center;
	padding-left: 5px;
}

.signup-event-center{	
	grid-area: desc;
	display: inline-block;
	vertical-align: top;
}

.signup-event-right {
	grid-area: tool;
	margin-right: 10px;
	margin-left: 10px;
	border-left: 1px solid #3e2e7e4d;
} 

.signup-event-bottom {	
	grid-area: details;
	width: 100%;		
}

.signup-event-time-text {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 19px;
	color: #f86264;
}

.signup-event-center-title {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */

	color: #3e2e7e;
}
.signup-event-center-level {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */

	color: #3e2e7e;
}
.signup-event-desc {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 10px;

	color: #383336;
}
.signup-event-desc-mark {
	font-size: 15px;
	color: #f86264;
}

.signup-event-bg-dark {	
	background: #f5f4f8;
}

.signup-event-bg-light {	
	background: #fdf7f7;	
}
