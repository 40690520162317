.card-details-pic-container {	
	display: grid;
	width: 300px;
    height: 210px;
	grid-template-rows: 30% 40% 30%px ;	
	grid-template-columns: 5% 90% 5%;	
	column-gap: 0;
	row-gap: 0px;
	justify-items: center;
	align-items: center ;
	background-image: url("./resources/dancing-couple.png");
	/* background-size: 343px 210px; */
	background-repeat: no-repeat;
	background-position: center;	
	border-radius: 30px;

	-webkit-box-shadow: 5px 5px 15px 0px #3E2E7E;
    -moz-box-shadow: 5px 5px 15px 0px #3E2E7E;
    box-shadow: 5px 5px 10px 0px #3E2E7E;
		
}

.card-details-pic-text-one {    
	grid-row: 1;
	grid-column: 2;
	z-index: 10;	
	color: #383336;    
	font-size: 26px;
}
.card-details-pic-text-two {    
	grid-row: 2;
	grid-column: 2;
	z-index: 10;	
	font-size: 20px;
}
.card-details-pic-text-three {    
	grid-row: 3;
	grid-column: 2;
	z-index: 10;
	font-size: 16px;		
}

.card-details-pic-text {

	margin-left: 5%;
	margin-right: 5%;	
	font-style: normal;
	font-weight: 600;
	
	line-height: 19px;
	color: white;
}
