.event-tool-container{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.event-tool {
	display: flex;
	flex-direction: column;
	font-size: x-small;
	justify-content: center;	
	align-content: center;
}

.event-tool-label {
	font-size: 10px;
	line-height: 14px;
	color: #383336;

	/* writing-mode: vertical-rl;
text-orientation: sideways-right;
transform: rotate(180deg);
font-size: x-small; */
	margin: 0;
	padding: 2px;
}

.event-tool-details {
	width: 100%;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	color: #383336;
	opacity: 0.8;
}
.event-tool-btn{	
	float: left;
	white-space: nowrap;
	margin: 2px;
	cursor: pointer;
}
