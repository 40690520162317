.input-container{
    height: 100%;
    width: 100%;    
    margin-bottom: 5px;
    margin-right: 5px;
}
.input-value{
    background-color: rgb(226, 226, 226);
    text-align: center;
    box-shadow: none;    
    padding: 0.5rem;
    margin-left: 0.5rem;    
}
.input-icon{    
    align-self: center;
    margin-right: 0.5rem;    
    margin-left: -30px; 
}