.picture-avatar-container {
	position: relative;
	border-radius: 10px;
	width: 100px;
	height: 100px;
}
.picture-avatar-picture {
	grid-row: 1;
	grid-column: 1;
	align-self: center;
	justify-self: center;
}
.picture-avatar-picture-overlay {
	grid-row: 1;
	grid-column: 1;
	position: absolute;
	top: 0;
	left: 35%;
}
.picture-avatar-picture-overlay svg {
	align-self: center;
	justify-self: center;
	width: 80px;
	height: 80px;
	margin-top: 10px;
}
