.dataviewoptions-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
}
.dataviewoptions-options{
    display: flex;
    flex-direction: row;    
    justify-content: space-evenly;
    
}

.dataviewoptions-title{
    
}
.dataviewoptions-options-item{    
    
}